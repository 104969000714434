// Needs to be listed first
import '@/classComponentHooks'

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import AppMgr from '@/appMgr';
import ServerMgr from '@/serverMgr';

Vue.config.productionTip = false;

Vue.prototype.$appMgr = new AppMgr();
Vue.prototype.$serverMgr = new ServerMgr(Vue.prototype.$appMgr);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// aws --region us-east-1 --profile kinbox s3 cp ./dist/.well-known/apple-app-site-association s3://clever-dev.khanacademykids.org/.well-known/apple-app-site-association --content-type application/json