






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TemplatePage extends Vue {

  created(): void {
    this.$appMgr.log('created: TemplatePage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: TemplatePage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: TemplatePage');
  }
}
