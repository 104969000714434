import { CleverUserType, MatchUser, CleverOnboardingRequest, CleverSection, CleverUser, 
  CleverOnboardingLoginResponse, CleverResponse 
} from '@/shared-libs/clever-types';

import * as consts from '@/consts';

import AppMgr from '@/appMgr';

export class ClientError extends Error {
  error: string;
  errorText: string;

  constructor(error: string, errorText: string) {
    super(errorText);

    Object.setPrototypeOf(this, ClientError.prototype);

    this.error = error;
    this.errorText = errorText;
  }
}

function replacer(key: any, value: any): any {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } 
  else {
    return value;
  }
}

export default class ServerMgr {
  private accessToken = '';
  private appMgr!: AppMgr;
  private availableStudents: number[] = [];
  private code: string = '';
  private cleverUser!: CleverUser;
  private cleverLoginResults!: CleverOnboardingLoginResponse;
  private cleverSections: CleverSection[] = [];
  private deleteKhanKidsClass: boolean = false;
  private embedded: boolean = false;
  private loggedIn = false;
  private mergedClassId: string = '';
  private mergedStudents: Map<string, string> = new Map();
  private sectionsToCreate: Set<String> = new Set();
  private url = consts.SERVER_URL;

  constructor(appMgr: AppMgr) {
    this.appMgr = appMgr;
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  getAvailableStudents(): number[] {
    return this.availableStudents;
  }

  showMergeData(): void {
    // const mapData = JSON.stringify(this.mergedStudents, replacer, 2)

    const mapData = JSON.stringify([...this.mergedStudents], null, 2);
    
    this.appMgr.log(`${this.mergedClassId}\n${mapData}`);

    // this.appMgr.log(`${this.mergedClassId}\n${JSON.stringify(this.mergedStudents, null, 2)}`);
  }

  setDeleteKhanKidsClass(deleteClass: boolean): void {
    this.deleteKhanKidsClass = deleteClass;
  }

  clearMergeData(): void {
    if (this.mergedClassId.length > 0) {
      this.updateSectionToCreate(false, this.mergedClassId);

      for (let section of this.cleverSections) {
        if (section.id == this.mergedClassId) {
          section.onboarded = false;
          
          break;
        }
      } 
    }

    this.mergedClassId = '';
    this.mergedStudents = new Map();

    this.updateAvailableStudents();
  }

  setMergeClassId(id: string): void {
    for (let section of this.cleverSections) {
      if (section.id == id) {
        section.onboarded = true;
        this.updateSectionToCreate(true, id);

        break;
      }
    }

    this.mergedClassId = id;
  }

  addMergedStudent(cleverUserId: string, khanKidsUserId: string): void {
    // this.appMgr.log(`${cleverUserId} -> ${khanKidsUserId}`);

    if (khanKidsUserId.length > 0) {
      this.mergedStudents.set(cleverUserId, khanKidsUserId);
    }
    else {
      this.mergedStudents.delete(cleverUserId);
    }

    this.updateAvailableStudents();
  }

  updateSectionToCreate(add: boolean, id: string): void {
    this.appMgr.log(`${add}, ${id}`);

    if (add) {
      this.sectionsToCreate.add(id);
    }
    else {
      this.sectionsToCreate.delete(id);
    }
  }

  hasConnectionInfo(): boolean {
    return this.code.length > 0 || this.accessToken.length > 0;
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }
  
  isEmbedded(): boolean {
    return this.embedded;
  }

  getLoginResults(): CleverOnboardingLoginResponse {
    return this.cleverLoginResults;
  }

  getSections(): CleverSection[] {
    return this.cleverSections;
  }

  setConnectionInfo(code: string, accessToken: string, embedded: boolean): void {
    this.accessToken = accessToken;
    this.code = code;
    this.embedded = embedded;
  }

  removeConnectionInfo() {
    this.accessToken = '';
    this.code = '';
  }

  getSectionsToCreate(): string[] {
    let sectionsToCreate: string[] = Array.from(this.sectionsToCreate, (x) => x.toString());

    return sectionsToCreate;
  }

  private updateAvailableStudents(): void {
    let khanKidsStudentSet: Set<string> = new Set();

    for (let value of this.mergedStudents.values()) {
      khanKidsStudentSet.add(value);
    }

    this.availableStudents = [];

    for (let j = 0; j < this.cleverLoginResults.khanKidsUsers.length; j++) {
      if (!khanKidsStudentSet.has(this.cleverLoginResults.khanKidsUsers[j].id)) {
        this.availableStudents.push(j);
      }
    }
  }

  async login(): Promise<CleverOnboardingLoginResponse> {    
    if (consts.LOCAL_SERVER_MODE) {
      this.cleverLoginResults = this.mockLogin();
      this.cleverSections = await this.getOnboardingSections()    

      this.updateAvailableStudents();

      this.appMgr.log(`login results:\n${JSON.stringify(this.cleverLoginResults, null, 2)}`);

      return this.cleverLoginResults;
    }

    if (this.loggedIn) {
      return this.cleverLoginResults;
    }

    if (this.code.length == 0 && this.accessToken.length == 0) {
      throw new Error('no credentials');
    }

    const body = {
      method: 'websiteLogin',
      code: this.code,
      accessToken: this.accessToken
    };

    const options = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',        
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body, null, 2)
    };

    let results: any;

    try {
      results = await this.fetchUrl(this.url, options);

      this.cleverLoginResults = results.result;
      this.accessToken = this.cleverLoginResults.accessToken;    
    }
    catch (error) {
      this.appMgr.log(`Login error: ${error}`);
      throw new Error('Error signing in')
    }
    
    this.appMgr.log(`login results:\n${JSON.stringify(results, null, 2)}`);

    if (results.error) {
      throw new ClientError(results.error, results.errorText);
    }

    this.cleverUser = this.cleverLoginResults.user;
    this.loggedIn = true;
    
    if (this.cleverUser.type == CleverUserType.Teacher) {
      this.updateAvailableStudents();
      this.cleverSections = await this.getOnboardingSections()    
    }

    return this.cleverLoginResults;    
  }

  getSection(sectionId: string): CleverSection {
    for (let section of this.cleverSections) {
      if (section.id == sectionId) {
        return section;
      }
    }

    throw new Error('No section found');
  }

  private async getOnboardingSections(): Promise<CleverSection[]> {
    if (this.cleverSections.length > 0) {
      return this.cleverSections;
    }

    if (consts.LOCAL_SERVER_MODE) {
      return this.getMockSections();
    }

    const body = {
      method: 'getOnboardingSections',
      userId: this.cleverUser.id,
      accessToken: this.accessToken,
    };

    const options = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',        
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body, null, 2)
    };

    try {
      const results = await this.fetchUrl(this.url, options);
        
      this.cleverSections = results.result.sections;

      this.appMgr.log(`sections:\n${JSON.stringify(this.cleverSections, null, 2)}`);

      return this.cleverSections;
    }
    catch (error) {
      this.appMgr.log(`getSections error: ${error}`);
      throw new Error('Error getting sections');
    }
  }

  async getSectionStudentsByIndex(sectionIndex: number): Promise<CleverUser[]> {
    let sectionId = this.cleverSections[sectionIndex].id;

    return await this.getSectionStudents(sectionId);
  }

  async getSectionStudents(sectionId: string): Promise<CleverUser[]> {
    if (consts.LOCAL_SERVER_MODE) {
      return this.getMockSectionStudents();
    }

    const body = {
      method: 'getSectionUsers',
      sectionId: sectionId,
      userType: CleverUserType.Student,
      accessToken: this.accessToken,
    };

    const options = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',        
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body, null, 2)
    };

    try {
      const results = await this.fetchUrl(this.url, options);
  
      // this.appMgr.log(`getSectionUsers results:\n${JSON.stringify(results, null, 2)}`);
      
      let sectionUsers: CleverUser[] = results.result.sectionUsers;
      
      this.appMgr.log(`getSectionUsers:\n${JSON.stringify(sectionUsers, null, 2)}`);

      return sectionUsers;
    }
    catch (error) {
      this.appMgr.log(`getSectionUsers error: ${error}`);
      throw new Error('Error getting section users');
    }
  }

  resetOnboardingData(): void {
    for (let section of this.cleverSections) {
      if (this.sectionsToCreate.has(section.id)) {
        section.onboarded = true;
      }
    }

    this.availableStudents = [];
    // this.cleverSections: CleverSection[] = [];
    this.deleteKhanKidsClass = false;
    this.mergedClassId = '';
    this.mergedStudents = new Map();
    this.sectionsToCreate = new Set();

    this.cleverLoginResults.hasCleverAccount = true;
    this.cleverLoginResults.khanKidsAccountId = '';
    this.cleverLoginResults.khanKidsTeacherUserId = '';
    this.cleverLoginResults.khanKidsGroupId = '';
    this.cleverLoginResults.khanKidsUsers = [];    
  }

  async onboardAccount(): Promise<CleverResponse> {
    let response: CleverResponse = { ok: true };
    let sectionsToCreate: string[] = Array.from(this.sectionsToCreate, (x) => x.toString());

    let cleverUser: CleverUser = {
      id: this.cleverUser.id,
      name: this.cleverUser.name,
      email: this.cleverUser.email,
      type: this.cleverUser.type,
      district: this.cleverUser.district,
      school: this.cleverUser.school ?? ''
    };

    let onboardRequest: CleverOnboardingRequest = {
      user: cleverUser,
      sectionsToCreate: sectionsToCreate,
      khanKidsAccountId: this.cleverLoginResults.khanKidsAccountId,
      khanKidsTeacherUserId: this.cleverLoginResults.khanKidsTeacherUserId,
      khanKidsGroupId: this.cleverLoginResults.khanKidsGroupId,
      deleteKhanKidsClass: this.deleteKhanKidsClass,
      mergedClassId: this.mergedClassId,
      mergedStudents: [...this.mergedStudents]
    }

    this.appMgr.log(`OnboardRequest:\n${JSON.stringify(onboardRequest, null, 2)}`);

    let mergedStudents: Map<string, string> = new Map(onboardRequest.mergedStudents);

    for (let [key, value] of mergedStudents.entries()) {
      this.appMgr.log(`${key} -> ${value}`);
    }

    if (consts.LOCAL_SERVER_MODE) {
      response.ok = true;
      return response;
    }

    const body = {
      method: 'onboardAccount',
      accessToken: this.accessToken,
      updateRequest: onboardRequest
    };

    const options = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',        
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body, null, 2)
    };

    try {
      const results = await this.fetchUrl(this.url, options);
  
      this.appMgr.log(`Onboard Account results:\n${JSON.stringify(results, null, 2)}`);

      this.resetOnboardingData();
      
      response.ok = results.result.ok;
    }
    catch (error) {
      this.appMgr.log(`callServer error: ${error}`);

      response.ok = false;
    }  
    
    return response;
  }

  async deleteAccount(): Promise<CleverResponse> {
    let response: CleverResponse = { ok: true };

    if (consts.LOCAL_SERVER_MODE) {
      response.ok = true;

      return response;
    }
    
    const body = {
      method: 'deleteAccount',
      accessToken: this.accessToken,
      cleverId: this.cleverUser.id
    };
    
    const options = {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',        
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body, null, 2)
    };
    
    try {
      const results = await this.fetchUrl(this.url, options);
      
      this.appMgr.log(`Delete Account results:\n${JSON.stringify(results, null, 2)}`);
      
      response.ok = results.result.ok;
    }
    catch (error) {
      response.ok = false;

      this.appMgr.log(`callServer error: ${error}`);
    }
    
    return response;
  }

  async fetchUrl(url: string, options: any): Promise<any> {
    try {
      const results = await fetch(url, options);
      const data = await results.json();
  
      // this.appMgr.log(results);

      return data;
    }
    catch (error) {
      this.appMgr.log(`fetchUrl: ${error}`);

      throw(error);
    }    
  }  

  mockLogin(): CleverOnboardingLoginResponse {
    let mockResults: any = {
        "ok": false,
        "accessToken": "ilc3a66ed87729c7d93de1c6d1a3fb9f26c771b9",
        "user": {
          "id": "5e5432a2c4c42c07c84e2134",
          "name": {
            "first": "Haley",
            "last": "Hahn",
            "middle": "D"
          },
          "email": "hahn_haley@example.com",
          "type": "teacher"
        },
        "hasCleverAccount": false,
        "khanKidsAccountId": "8350eb20-f8cb-11eb-977a-bd2b52f49f3b",
        "khanKidsTeacherUserId": "c3b6d1c0-f8cb-11eb-977a-bd2b52f49f3b",
        "khanKidsGroupId": "8350eb20-f8cb-11eb-977a-bd2b52f49f3b",
        "khanKidsUsers": [
          {
            "id": "de519470-f8cb-11eb-977a-bd2b52f49f3b",
            "name": "Coco"
          },
          {
            "id": "de8e7660-f8cb-11eb-977a-bd2b52f49f3b",
            "name": "Happy"
          },          
          {
            "id": "ded172d0-f8cb-11eb-977a-bd2b52f49f3b",
            "name": "Latte"
          },
          {
            "id": "df113af0-f8cb-11eb-977a-bd2b52f49f3b",
            "name": "Mocha"
          }
        ]
    };

 
    this.cleverUser = mockResults.user;

    return mockResults;
  }

  getMockSections(): CleverSection[] {
    let mockResults: any = 
    [
      {
        "id": "5e5432a2c4c42c07c84e2169",
        "name": "Class 101, Homeroom - Hahn - 0",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "5e5432a2c4c42c07c84e216c",
        "name": "Grade 1 Math, Class 101 - Hahn - 2",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "5e5432a2c4c42c07c84e216f",
        "name": "Grade 1 Music, Class 101 - Hahn - 5",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "5e5432a2c4c42c07c84e2172",
        "name": "Grade 1 Reading, Class 101 - Hahn - 1",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "5e5432a2c4c42c07c84e2175",
        "name": "Grade 1 Science, Class 101 - Hahn - 2",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "5e5432a2c4c42c07c84e2178",
        "name": "Grade 1 Social Studies, Class 101 - Hahn - 3",
        "grade": "1",
        "onboarded": false
      },
      {
        "id": "60c2ed0fedbf850048bd7f7d",
        "name": "Multiple Teacher Section",
        "grade": "1",
        "onboarded": false
      }
    ];

    this.cleverSections = mockResults;
    
    return mockResults;
  }

  getMockSectionStudents(): CleverUser[] {
    let mockData: any = 
    [
      {
        "id": "5e5432a2c4c42c07c84e210b",
        "name": {
          "first": "David",
          "last": "Considine",
          "middle": "M"
        },
        "email": "c_david@example.org",
        "type": "student",
        "grade": "1"
      },
      {
        "id": "5e5432a2c4c42c07c84e210e",
        "name": {
          "first": "Angelo",
          "last": "Sawayn",
          "middle": "S"
        },
        "email": "s.angelo@example.net",
        "type": "student",
        "grade": "1"
      }
    ];

    return mockData;
  }
}