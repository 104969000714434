import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    debugResults: '',
    embedded: false,
    isActivityIndicatorVisible: false,
    activityIndicatorText: '',
    isModalDialogVisible: false,
    modalDialogTitle: '',
    modalDialogBody: '',
    modalDialogOkCancel: false,
    availableStudents: false
  },
  mutations: {
    setDebugResults(state, results) {
      state.debugResults = results;
    },
    setEmbedded(state, results) {
      state.embedded = results;
    },
    setIsActivityIndicatorVisible(state, results) {
      state.isActivityIndicatorVisible = results;
    },
    setActivityIndicatorText(state, results) {
      state.activityIndicatorText = results;
    },
    setIsModalDialogVisible(state, results) {
      state.isModalDialogVisible = results;
    },
    setModalDialogTitle(state, results) {
      state.modalDialogTitle = results;
    },
    setModalDialogBody(state, results) {
      state.modalDialogBody = results;
    },
    setModalDialogOkCancel(state, results) {
      state.modalDialogOkCancel = results;
    },
    toggleAvailableStudents(state, results) {
      state.availableStudents = !state.availableStudents;
    }                   
  },
  getters: {
    embedded: (state) => {
      return state.embedded;
    }
  },
  actions: {
    async showActivityIndicator ({ commit, state }, text) {
      if (state.isActivityIndicatorVisible) {
        return;
      }

      commit('setActivityIndicatorText', text);
      commit('setIsActivityIndicatorVisible', true);
    }, 
    async hideActivityIndicator ({ commit, state }) {
      if (!state.isActivityIndicatorVisible) {
        return;
      }

      commit('setIsActivityIndicatorVisible', false);
    },
    async showModalDialog ({ commit, state }, dialogData) {
      if (state.isModalDialogVisible) {
        return;
      }

      commit('setModalDialogTitle', dialogData.title);
      commit('setModalDialogBody', dialogData.body);
      commit('setModalDialogOkCancel', dialogData.okCancel);
      commit('setIsModalDialogVisible', true);
    },    
    async hideModalDialog ({ commit, state }) {
      if (!state.isModalDialogVisible) {
        return;
      }

      commit('setIsModalDialogVisible', false);
    },      
  },
  modules: {},
});
