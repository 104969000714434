















import { Component, Prop, Vue } from "vue-property-decorator";

import { CleverUser, CleverDashboardAuth, CleverDashboardAuthSrc, CleverResponse } from '@/shared-libs/clever-types';
import { ModalDialogData } from '@/appMgr';

import * as consts from '@/consts';

import TeacherCard from "@/components/TeacherCard.vue";
import ClassList from "@/components/ClassList.vue";

@Component({
  components: {
    TeacherCard,
    ClassList
  }
})
export default class ClassSelectPage extends Vue {
  @Prop({ default: '' }) goBackPage!: string;

  private activityText: string = 'Adding Classes...';
  private teacher: CleverUser = {
    id: '',
    name: {
      first: '',
      last: ''
    },
    district: ''
  }

  created() {
    this.$appMgr.log('created: ClassSelectPage');
    this.$appMgr.log(`goBackPage: ${this.goBackPage}`);

    let loginResults = this.$serverMgr.getLoginResults();
  
    this.teacher = loginResults.user;
  }
  
  mounted(): void {
    this.$appMgr.log('mounted: ClassSelectPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: ClassSelectPage');
  }

  hasGoBackPage(): boolean {
    return this.goBackPage.length > 0;
  }

  goBack(): void {
    this.$router.replace({ name: this.goBackPage });
  }

  confirm() {
    let sectionsToCreate = this.$serverMgr.getSectionsToCreate();

    if (sectionsToCreate.length == 0) {
      const dialogData: ModalDialogData = {
        title: consts.ERR_NO_CLASSES_SELECTED_TITLE,
        body: consts.ERR_NO_CLASSES_SELECTED,
        okCancel: false,
        okCallback: this.modalOk
      };

      this.$appMgr.showModalDialog(dialogData);

      return;
    }

    this.onboardAccount();
  }

  modalOk() {
    this.$appMgr.hideModalDialog();
  }

  async onboardAccount() {
    let error = false;
    let results: CleverResponse;

    this.$appMgr.showActivityIndicator(this.activityText);
    this.$store.commit('setDebugResults', 'Onboarding Account...');

    // error = true;
    try {
      results = await this.$serverMgr.onboardAccount();

      error = !results.ok;
    }
    catch(error) {
      this.$appMgr.log(`Onboarding error: ${JSON.stringify(error, null, 2)}`);

      error = true;
      this.$store.commit('setDebugResults', 'Onboarding Error!');
    }

    this.$appMgr.hideActivityIndicator();
      
    this.$appMgr.log(`Onboarding results: \n${JSON.stringify(results!, null, 2)}`);

    if (error) {
      const dialogData: ModalDialogData = {
        title: consts.ERR_CANNOT_CREATE_CLASSES_TITLE,
        body: consts.ERR_CANNOT_CREATE_CLASSES,
        okCancel: false,
        okCallback: this.modalOk
      };

      this.$appMgr.showModalDialog(dialogData); 
      
      return;
    }

    this.$store.commit('setDebugResults', 'Onboarding Ok!');

    const auth = this.$appMgr.getAuth();
    const loginResponse = this.$serverMgr.getLoginResults();

    if (this.$store.getters.embedded) {
      this.$router.replace({ name: 'EmbeddedSuccessPage' });
    }
    else if (auth.src == CleverDashboardAuthSrc.DashboardOnboard || loginResponse.hasReportsEnabled) {
      const newAuth: CleverDashboardAuth = {
        src: CleverDashboardAuthSrc.DashboardSignIn,
        code: '',
        accessToken: loginResponse.accessToken,
        url: ''
      };

      let url: string;

      if (loginResponse.hasReportsEnabled) {
        url = consts.DASHBOARD_URL;
      }
      else {
        url = auth.url;
      }      

      window.location.replace(`${url}?state=${btoa(JSON.stringify(newAuth))}`);
    }
    else {
      this.$router.replace({ name: 'TeacherMainPage' });
    }
  }
}    

