export enum CleverUserType {
  Student = 'student',
  Teacher = 'teacher',
  DistrictAdmin = 'district_admin',
  SchoolAdmin = 'school_admin'
}

export interface CleverState {
  accessToken?: string;
  userCleverId?: string;
  userId?: string;
  userType?: CleverUserType;
}

export interface CleverMeData {
  id: string;
  type: string;
  authorized_by: string;
}

export interface CleverName {
  first: string;
  last: string;
}

export interface CleverMe {
  type: string;
  data: CleverMeData;
}

export interface CleverUser {
  id: string;
  email?: string;
  name: CleverName;
  type?: string;
  grade?: string;
  district: string;
  school?: string;
  schools?: string[];
}

export interface CleverSection {
  id: string;
  name: string;
  grade?: string;
  school: string;
  onboarded?: boolean;
  teacher?: string;
  teachers?: string[];
  students?: string[];
}

export interface CleverSchool {
  id: string;
  name: string;
  district: string;
}

export interface CleverDistrict {
  id: string;
  name: string;
}

export interface CleverDistrictToken {
  id: string;
  accessToken: string;
}

export interface CleverResponse {
  ok: boolean;
  errorMessage?: string;
}

export interface CleverOnboardingLoginResponse extends CleverResponse {
  accessToken: string;
  user: CleverUser;
  hasCleverAccount: boolean;
  hasReportsEnabled: boolean;
  khanKidsAccountId: string;
  khanKidsTeacherUserId: string;
  khanKidsGroupId: string;
  khanKidsUsers: MatchUser[];
}

export interface MatchUser {
  id: string;
  name: string;
}

export interface CleverOnboardingRequest {
  user: CleverUser;
  sectionsToCreate: string[];
  khanKidsAccountId: string;
  khanKidsTeacherUserId: string;
  khanKidsGroupId: string;  
  deleteKhanKidsClass: boolean;
  mergedClassId: string;
  mergedStudents: [string, string][];
}

export interface CleverConfig {
  clientId: string;
  clientSecret: string;
  redirectUrl: string;
}

export enum CleverDashboardAuthSrc {
  DashboardSignIn = 'DashboardSignIn',
  DashboardOnboard = 'DashboardOnboard',
  CleverDashboardSignIn = 'CleverDashboardSignIn',
  CleverPortalSignIn = 'CleverPortalSignIn'
}

export interface CleverDashboardAuth {
  src: CleverDashboardAuthSrc;
  code: string;
  accessToken: string;
  url: string;
};

