



















import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class ExistingAccountPage extends Vue {
  created() {
    this.$appMgr.log(`created: ExistingAccountPage`);
  }

  mounted(): void {
    this.$appMgr.log('mounted: ExistingAccountPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: ExistingAccountPage');
  }

  deleteExistingClass(): void {
    this.$serverMgr.setDeleteKhanKidsClass(true);
    this.$router.replace({ name: 'ClassSelectPage' });
  }

  mergeExistingClass(): void {
    this.$router.replace({ name: 'MatchClassPage' });
  }
}

