















import { mapGetters } from 'vuex'

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: mapGetters([
  'embedded'
  ])  
})export default class StudentWelcomePage extends Vue {

  created(): void {
    this.$appMgr.log('created: StudentWelcomePage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: StudentWelcomePage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: StudentWelcomePage');
  }

  gotoStudenMainPage(): void {
    this.$router.replace({ name: 'StudentMainPage' });
  }
}
