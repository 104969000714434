







import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class BaseCheckbox extends Vue {
  @Prop() checked!: boolean;
  
  private selected: boolean = false;

  @Watch('checked')
  onCheckChanged(val: boolean, oldVal: boolean) {
    this.$appMgr.log(`checkbox watch: ${val}`);

    this.selected = val;
  }

  toggle(): void {
    this.selected = !this.selected;

    this.$emit('checkbox-toggled', this.selected);
  }

  created(): void {
    this.selected = false;

    this.$appMgr.log('created');
  }

  // mounted(): void {
  //   this.$appMgr.log('mounted');
  // }

  // destroyed(): void {
  //   this.$appMgr.log('destroyed');
  // }
}
