














import { Component, Prop, Vue } from "vue-property-decorator";

import * as consts from '@/consts';

@Component({})
export default class EmbeddedSuccessPage extends Vue {
  created(): void {
    this.$appMgr.log('created: EmbeddedSuccessPage');
    // this.$appMgr.log(`embedded: ${this.$store.getters.embedded}`);
  }

  mounted(): void {
    this.$appMgr.log('mounted: EmbeddedSuccessPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: EmbeddedSuccessPage');
  }

  done(): void {
    window.location.href = consts.EMBEDDED_ONBOARDING_OK;
  }
}
