











import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import { MatchUser } from '@/shared-libs/clever-types';

import BaseDropdown from "@/components/BaseDropdown.vue";

@Component({
  components: {
    BaseDropdown
  }   
})
export default class StudentMatch extends Vue {
  @Prop() cleverStudent!: MatchUser;
  @Prop() tabIndex!: number;

  private studentNames: string[] = ['None'];
  private availableStudents: number[] = [];
  private selectedStudent: number = -1;

  created(): void {
    this.updateAvailableStudents();
  }

  // mounted(): void {
  //   this.$appMgr.log('mounted');
  // }

  // destroyed(): void {
  //   this.$appMgr.log('destroyed');
  // }

  @Watch('$store.state.availableStudents')
  onAvailableStudentsChanged(val: boolean, oldVal: boolean) {
    this.$appMgr.log(`students changed`);

    this.updateAvailableStudents();
  }

  updateAvailableStudents(): void {
    const loginResults = this.$serverMgr.getLoginResults();

    this.availableStudents = this.$serverMgr.getAvailableStudents();
    this.studentNames = ['None'];

    for (let availableStudent of this.availableStudents) {
      this.studentNames.push(loginResults.khanKidsUsers[availableStudent].name);
    }
  }

  onStudentSelected(student: number) {
    const loginResults = this.$serverMgr.getLoginResults();

    // this.$appMgr.log(`Student selected: ${student}, ${this.khanKidsStudents[student]}`);
    
    if (student == 0) {
      this.selectedStudent = -1

      this.$serverMgr.addMergedStudent(this.cleverStudent.id, '');
    }
    else {
      this.selectedStudent = this.availableStudents[student - 1];

      const khanKidsUserId = loginResults.khanKidsUsers[this.selectedStudent].id;

      this.$serverMgr.addMergedStudent(this.cleverStudent.id, khanKidsUserId);
    }

    this.$store.commit('toggleAvailableStudents');
  }
}
