




























import { Component, Prop, Vue } from "vue-property-decorator";

import { CleverUser, MatchUser } from '@/shared-libs/clever-types';
import { ClientError } from "@/serverMgr";

import { ModalDialogData } from '@/appMgr';
import * as consts from '@/consts';

import TeacherCard from "@/components/TeacherCard.vue";
import BaseDropdown from '@/components/BaseDropdown.vue';
import StudentMatch from '@/components/StudentMatch.vue';

@Component({
  components: {
    TeacherCard,
    BaseDropdown,
    StudentMatch
  }
})
export default class MatchClassPage extends Vue {
  private activityText: string = 'Adding Classes...';
  private cleverClasses: string[] = ['None'];
  private cleverStudents: MatchUser[] = [];
  private defaultClass: string = '';
  private selectedClass: number = 0;

  private teacher: CleverUser = {
    id: '',
    name: {
    first: '',
    last: ''
    },
    district: ''
  };

  created() {
    this.$appMgr.log(`created: MatchClassPage`);

    let loginResults = this.$serverMgr.getLoginResults();
    let sections = this.$serverMgr.getSections();

    this.teacher = loginResults.user;

    for (let section of sections) {
      this.cleverClasses.push(section.name);
    }
  }

  confirm() {
    this.$appMgr.log('Confirm');

    if (this.selectedClass == 0) {
      const dialogData: ModalDialogData = {
        title: consts.ERR_MATCH_CLASS_NO_CLASS_SELECTED_TITLE,
        body: consts.ERR_MATCH_CLASS_NO_CLASS_SELECTED,
        okCancel: false,
        okCallback: this.onNoClassOk
      };

      this.$appMgr.showModalDialog(dialogData);
    }
    else if (this.$serverMgr.getAvailableStudents().length != 0) {
      const dialogData: ModalDialogData = {
        title: consts.ERR_MATCH_CLASS_NOT_ALL_MATCHED_TITLE,
        body: consts.ERR_MATCH_CLASS_NOT_ALL_MATCHED,
        okCancel: true,
        okCallback: this.onNotMatchedOk,
        cancelCallback: this.onNotMatchedCancel
      };

      this.$appMgr.showModalDialog(dialogData);
    }
    else {
      this.$router.replace({ name: 'ClassSelectPage' });
    }
  }

  onNoClassOk() {
    this.$appMgr.hideModalDialog();
  }

  onNotMatchedOk() {
    // const loginResults = this.$serverMgr.getLoginResults();

    // if (this.$serverMgr.getAvailableStudents().length == loginResults.khanKidsUsers.length) {
    //   this.$serverMgr.setDeleteKhanKidsClass(true);
    // }

    this.$appMgr.hideModalDialog();

    this.$router.replace({ name: 'ClassSelectPage' });
  }

  onNotMatchedCancel() {
    this.$appMgr.hideModalDialog();
  }

  onClassSelected(option: number) {
    this.$appMgr.log(`Class selected: ${option}, ${this.cleverClasses[option]}`);

    this.selectedClass = option;

    if (option == 0) {
      this.$serverMgr.clearMergeData();
      this.cleverStudents = [];
    }
    else {
      this.$serverMgr.clearMergeData();
      this.$serverMgr.setMergeClassId(this.$serverMgr.getSections()[option - 1].id);
      this.getSectionStudentsByIndex(option - 1);
    }
  }

  async getSectionStudentsByIndex(index: number) {
    this.$appMgr.showActivityIndicator('Getting Students...');

    this.cleverStudents = [];

    try {
      const sectionStudents = await this.$serverMgr.getSectionStudentsByIndex(index);

      for (let sectionStudent of sectionStudents) {
        let matchUser: MatchUser = {
          name: `${sectionStudent.name.first} ${sectionStudent.name.last}`,
          id: sectionStudent.id
        };

        this.cleverStudents.push(matchUser);
      }

      this.$appMgr.hideActivityIndicator();
    }
    catch(error) {
      this.$appMgr.hideActivityIndicator();
    }
  }

  mounted(): void {
    this.$appMgr.log('mounted: MatchClassPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: MatchClassPage');
  }
}    

