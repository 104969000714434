








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActivityIndicator extends Vue {
  @Prop() text!: string;

  created() {
    this.$appMgr.log(`created`);
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }
}

