







import { Component, Prop, Vue } from 'vue-property-decorator';

import { 
  CleverOnboardingLoginResponse, CleverSection, CleverUserType, 
  CleverDashboardAuth, CleverDashboardAuthSrc 
} from '@/shared-libs/clever-types';

import { ModalDialogData } from '@/appMgr';
import { ClientError } from '@/serverMgr';

import * as consts from '@/consts';

import ActivityIndicator from '@/components/ActivityIndicator.vue';

@Component({
  components: {
    ActivityIndicator
  }
})
export default class SigningInPage extends Vue {
  created() {
    this.$appMgr.log('created: SigningIn');
  }

  mounted(): void {
    this.$appMgr.log('mounted: SigningIn');

    if (!consts.LOCAL_SERVER_MODE) {
      this.login();
    }
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: SigningIn');
  }

  modalOk() {
    this.$appMgr.hideModalDialog();
    this.$serverMgr.removeConnectionInfo();
    
    this.$router.replace({ name: 'SignInPage' });
  }

  async login() {
    let results: CleverOnboardingLoginResponse;

    try {
      results = await this.$serverMgr.login();

      // this.$store.commit('setDebugResults', `Login Ok: hca: ${results.hasCleverAccount}, hkka: ${results.khanKidsAccountId}`);
    
      if (results.user.type == CleverUserType.Teacher) {
        if (results.khanKidsAccountId.length > 0) {
          if (results.khanKidsTeacherUserId.length > 0) {
            this.$router.replace({ name: 'ExistingAccountPage' });
          }
          else {
            this.$router.replace({ name: 'ErrorPage', params: { title: consts.ERR_NEED_CLASS_ACCOUNT_TITLE,  message: consts.ERR_NEED_CLASS_ACCOUNT} });
          }          
        }
        else {
          let hasOnboardedSections = false;
          let sections: CleverSection[] = this.$serverMgr.getSections();

          for (let section of sections) {
            if (section.onboarded) {
              hasOnboardedSections = true;
              break;
            }
          }  

          const auth = this.$appMgr.getAuth();

          if (auth.src != CleverDashboardAuthSrc.DashboardOnboard && hasOnboardedSections && results.hasCleverAccount && results.hasReportsEnabled) {
            const newAuth: CleverDashboardAuth = {
              src: CleverDashboardAuthSrc.DashboardSignIn,
              code: '',
              accessToken: results.accessToken,
              url: ''
            };

            let url = auth.url;

            if (!url) {
              url = consts.DASHBOARD_URL;
            }

            window.location.replace(`${url}?state=${btoa(JSON.stringify(newAuth))}`);

            return;
          }

          if (hasOnboardedSections) {
            this.$router.replace({ name: 'TeacherMainPage' });
          }
          else {
            this.$router.replace({ name: 'TeacherWelcomePage' });
          }
        }     
      }
      else {
        this.$router.replace({ name: 'StudentWelcomePage' });
      }
    }
    catch(error) {
      let dialogData: ModalDialogData = {
        title: consts.ERR_SIGNING_IN_TITLE,
        body: consts.ERR_SIGNING_IN,
        okCancel: false,
        okCallback: this.modalOk
      };

      if (error instanceof ClientError) {
        // this.$store.commit('setDebugResults', `Login Failed: ${error.errorText}`);

        this.$appMgr.log(`Login error: ${error.error} - ${error.errorText}`);
      }
      else {
        this.$appMgr.log(`Login error: ${error}`);
      }

      this.$appMgr.showModalDialog(dialogData);       
    }
  }
}

