







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class SamplePage extends Vue {
  created() {
    this.$appMgr.log(`created: SamplePage`);
  }

  mounted(): void {
    this.$appMgr.log('mounted: SamplePage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: SamplePage');
  }
}

