






















import { mapGetters } from 'vuex'

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: mapGetters([
  'embedded'
  ])  
})export default class TeacherMainPage extends Vue {

  created(): void {
    this.$appMgr.log('created: TeacherMainPage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: TeacherMainPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: TeacherMainPage');
  }

  gotoClassSelectPage(): void {
    this.$router.replace({ name: 'ClassSelectPage', params: { goBackPage: 'TeacherMainPage'} });
  }
}
