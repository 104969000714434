
















import { mapGetters } from 'vuex'

import { Component, Prop, Vue } from "vue-property-decorator";

import { CleverDashboardAuth, CleverDashboardAuthSrc } from '@/shared-libs/clever-types';

import * as consts from '@/consts';


@Component({
  computed: mapGetters([
  'embedded'
  ])  
})
export default class WelcomePage extends Vue {

  created(): void {
    this.$appMgr.log('created: WelcomePage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: WelcomePage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: WelcomePage');
  }

  signIn(): void {
    const auth: CleverDashboardAuth = {
      src: CleverDashboardAuthSrc.CleverDashboardSignIn,
      code: '',
      accessToken: '',
      url: ''
    };

    window.location.replace(`${consts.CLEVER_AUTHORIZE_URL}&state=${btoa(JSON.stringify(auth))}`);    
    // window.location.href = consts.CLEVER_AUTHORIZE_URL;
  }
}
