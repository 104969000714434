
















import { Component, Prop, Vue } from "vue-property-decorator";

import { mapGetters } from 'vuex'

import * as consts from '@/consts';

@Component({
  computed: mapGetters([
  'embedded'
  ])  
})
export default class TeacherWelcomePage extends Vue {
  @Prop() launchedFromPortal!: string;

  private message: string = '';

  created(): void {
    this.$appMgr.log('created: TeacherWelcomePage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: TeacherWelcomePage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: TeacherWelcomePage');
  }

  start() {
    this.$router.replace({ name: 'ClassSelectPage' });
  }
}
