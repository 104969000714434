











import { Component, Prop, Watch, Vue } from "vue-property-decorator";

import ClassCard from "@/components/ClassCard.vue";
import { CleverSection } from '@/shared-libs/clever-types';

@Component({
  components: {
    ClassCard
  },
})
export default class ClassList extends Vue {
  private classList: CleverSection[] = [];

  created(): void {
    this.$appMgr.log('created');

    this.getSections();   
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }

  async getSections(): Promise<void> {
   this.classList = this.$serverMgr.getSections();    
  }
}
