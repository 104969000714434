











import { Component, Prop, Vue } from "vue-property-decorator";

import Modal from '@/components/Modal.vue';
import ActivityIndicator from '@/components/ActivityIndicator.vue';

@Component({
  components: {
    Modal,
    ActivityIndicator
  },
})
export default class ModalActivityIndicator extends Vue {
  @Prop() text!: string;

  created(): void {
    this.$appMgr.log('created');
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }
}
