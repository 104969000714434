




































import { mapState } from 'vuex'
import { Component, Prop, Vue } from 'vue-property-decorator';

import { CleverDashboardAuth, CleverDashboardAuthSrc } from '@/shared-libs/clever-types';

import * as consts from '@/consts';

import ModalActivityIndicator from '@/components/ModalActivityIndicator.vue';
import ModalDialog from '@/components/ModalDialog.vue';

@Component({
  components: {
    ModalActivityIndicator,
    ModalDialog
  },  
  computed: mapState([
  'debugResults',
  'embedded',
  'isActivityIndicatorVisible',
  'activityIndicatorText',
  'isModalDialogVisible',
  'modalDialogTitle',
  'modalDialogBody',
  'modalDialogOkCancel'
  ])  
})
export default class App extends Vue {
  private isLocalServerMode: boolean = consts.LOCAL_SERVER_MODE;
  private showDropdown = false;

  mounted(): void {
    this.$appMgr.log('mounted: App');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: App');
  }

  created(): void {
    const uri = window.location.search.substring(1);
    const searchParams = new URLSearchParams(uri);

    let accessToken = '';
    let code = '';
    let isEmbedded = false;    
    let state = '';

    this.$appMgr.log('created: App.vue');   

    for (const [key, value] of searchParams) {
      if (key == 'code') {
        code = value;
      }
      else if (key == 'accessToken') {
        accessToken = value;
      }      
      else if (key == 'embedded') {
        isEmbedded = (value == 'true');
      }
      else if (key == 'state') {
        state = value;
      }

      this.$appMgr.log(`${key} : ${value}`);
    }

    if (state) {
      this.$appMgr.setAuth(state);

      const auth = this.$appMgr.getAuth();

      console.log(`state:\n${JSON.stringify(auth, null, 2)}`);

      if (auth.src == CleverDashboardAuthSrc.CleverDashboardSignIn) {
      }
      else if (auth.src == CleverDashboardAuthSrc.DashboardSignIn) {
        const newAuth: CleverDashboardAuth = {
          src: auth.src,
          code: code,
          accessToken: '',
          url: ''
        };  

        window.location.replace(`${auth.url}?state=${btoa(JSON.stringify(newAuth))}`);
        
        return;
      }
      else if (auth.src == CleverDashboardAuthSrc.DashboardOnboard) {
        accessToken = auth.accessToken;
      }      
    }

    this.$store.commit('setEmbedded', isEmbedded);

    if (consts.LOCAL_SERVER_MODE) {
      this.$serverMgr.login();
      this.$router.replace({ name: 'WelcomePage' });
      return;
    }

    if (code.length > 0 || accessToken.length > 0) {
      this.$serverMgr.setConnectionInfo(code, accessToken, isEmbedded);
    }

    if (uri.length > 0) {
      history.replaceState(null, '', '/'); 
    }

    if (this.$serverMgr.hasConnectionInfo()) {
      this.$router.replace({ name: 'SigningInPage' });
    }
    else {
      this.$router.replace({ name: 'WelcomePage' });
    }
  }

  modalOk(): void {
    this.$appMgr.dispatchModalDialogOk();
  }
  
  modalCancel(): void {
    this.$appMgr.dispatchModalDialogCancel();
  }

  gotoTestPage(name: string) {
    this.$router.replace({ name: name });
  }

  gotoTestErrorPage() {
    this.$router.replace({ name: 'ErrorPage', params: { title: 'Oops!',  message: 'A test error has occurred!'} });
  }

  toggleShowDropdown() {
    this.showDropdown = !this.showDropdown;
  }
}
