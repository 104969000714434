








import { Component, Prop, Vue } from "vue-property-decorator";

import { CleverUser } from '@/shared-libs/clever-types';

@Component
export default class TeacherCard extends Vue {
  @Prop() teacher!: CleverUser;

  fullName(): string {
    return `${this.teacher.name.first} ${this.teacher.name.last}`;
  }

  created(): void {
    this.$appMgr.log('created');
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }
}
