


















import { Component, Prop, Vue } from "vue-property-decorator";

import * as consts from '@/consts';

import BaseCheckbox from "@/components/BaseCheckbox.vue";

@Component({
  components: {
    BaseCheckbox
  },
})export default class ClassCard extends Vue {
  @Prop() name!: string;
  @Prop() grade!: string;
  @Prop() id!: string;
  @Prop({ default: false }) disabled!: boolean;

  checkboxToggled(value: boolean): void {
    this.$appMgr.log(`Checkbox Toggled: ${value} for ${this.name}`);

    this.$serverMgr.updateSectionToCreate(value, this.id);
  }

  // created(): void {
  //   this.$appMgr.log('created');
  // }

  // mounted(): void {
  //   this.$appMgr.log('mounted');
  // }

  // destroyed(): void {
  //   this.$appMgr.log('destroyed');
  // }
}
