import store from "./store";

import { CleverDashboardAuthSrc, CleverDashboardAuth } from '@/shared-libs/clever-types';

import * as consts from '@/consts';

type okCancelCallback = () => void;

export interface ModalDialogData {
  title: string;
  body: string;
  okCancel: boolean;
  okCallback: okCancelCallback;
  cancelCallback?: okCancelCallback;
}

function emptyCallback(): void {
}

export default class AppMgr {
  private cancelCallback: okCancelCallback;
  private okCallback: okCancelCallback;
  private auth: CleverDashboardAuth;

  constructor() {
    this.cancelCallback = emptyCallback;
    this.okCallback = emptyCallback;

    const auth: CleverDashboardAuth = {
      src: CleverDashboardAuthSrc.CleverPortalSignIn,
      code: '',
      accessToken: '',
      url: ''
    };  

    this.auth = auth;
  }

  log(text: string): void {
    if (consts.CONSOLE_LOGGING) {
      console.log(text);
    }
  }

  testCallback(): void {
    this.log('testCallback called');
  }

  getAuth(): CleverDashboardAuth {
    return this.auth;
  }

  setAuth(valueBase64: string): void {
    this.auth = JSON.parse(atob(valueBase64));
  }

  async showActivityIndicator(text: string): Promise<void> {
    store.dispatch('showActivityIndicator', text);
  }

  async hideActivityIndicator(): Promise<void> {
    store.dispatch('hideActivityIndicator');
  }

  async showModalDialog(dialogData: ModalDialogData): Promise<void> {
    this.okCallback = dialogData.okCallback;
    this.cancelCallback = dialogData.cancelCallback ?? emptyCallback;
    
    store.dispatch('showModalDialog', dialogData);
  }

  async hideModalDialog(): Promise<void> {
    this.okCallback = emptyCallback;
    this.cancelCallback = emptyCallback;

    store.dispatch('hideModalDialog');
  }

  async dispatchModalDialogOk(): Promise<void> {
    this.log('modalOk called in AppMgr');

    this.okCallback();
  }

  async dispatchModalDialogCancel(): Promise<void> {
    this.cancelCallback();
  }
}
