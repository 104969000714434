











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  created(): void {
    this.$appMgr.log('created');
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }

  close() {
    this.$emit('close');  
  }
}
