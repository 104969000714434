





















import { mapGetters } from 'vuex'

import { Component, Prop, Vue } from "vue-property-decorator";

import * as consts from '@/consts';

@Component({
  computed: mapGetters([
  'embedded'
  ])  
})
export default class StudentMainPage extends Vue {
  private onboardingText: string = '';

  created(): void {
    this.$appMgr.log('created: StudentMainPage');

    let loginResults = this.$serverMgr.getLoginResults();
  
    if (loginResults.hasCleverAccount) {
      this.onboardingText = consts.STRING_STUDENT_ONBOARDED;
    }
    else {
      this.onboardingText = consts.STRING_STUDENT_NOT_ONBOARDED;
    }  
  }

  mounted(): void {
    this.$appMgr.log('mounted: StudentMainPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: StudentMainPage');
  }
}
