




















import { Component, Prop, Vue } from "vue-property-decorator";

import Modal from '@/components/Modal.vue';

@Component({
  components: {
    Modal
  },
})
export default class ModalDialog extends Vue {
  @Prop() title!: string;
  @Prop() body!: string;
  @Prop() okCancel!: boolean;

  created(): void {
    this.$appMgr.log('created');
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }

  ok() {
    this.$emit('ok');
  }

  cancel() {
    this.$emit('cancel');
  }
}
