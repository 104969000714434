import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import TemplatePage from "@/pages/TemplatePage.vue";
import QueenyTestPage from "@/pages/QueenyTestPage.vue";

import SigningInPage from "@/pages/SigningInPage.vue";
import ClassSelectPage from "@/pages/ClassSelectPage.vue";
import SamplePage from "@/pages/SamplePage.vue";
import EmbeddedSuccessPage from "@/pages/EmbeddedSuccessPage.vue";
import MatchClassPage from "@/pages/MatchClassPage.vue";
import ExistingAccountPage from "@/pages/ExistingAccountPage.vue";
import WelcomePage from "@/pages/WelcomePage.vue";
import TeacherWelcomePage from "@/pages/TeacherWelcomePage.vue";
import TeacherMainPage from "@/pages/TeacherMainPage.vue";
import StudentWelcomePage from "@/pages/StudentWelcomePage.vue";
import StudentMainPage from "@/pages/StudentMainPage.vue";
import ErrorPage from "@/pages/ErrorPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [    
  {
    path: "/template",
    name: "TemplatePage",
    component: TemplatePage
  },  
  {
    path: "/queeny-test",
    name: "QueenyTestPage",
    component: QueenyTestPage
  },
  {
    path: "/signing-in",
    name: "SigningInPage",
    component: SigningInPage,
    props: true
  },  
  {
    path: "/existing-account",
    name: "ExistingAccountPage",
    component: ExistingAccountPage
  },   
  {
    path: "/match-class",
    name: "MatchClassPage",
    component: MatchClassPage
  },    
  {
    path: "/class-select",
    name: "ClassSelectPage",
    component: ClassSelectPage,
    props: true
  },  
  {
    path: "/sample",
    name: "SamplePage",
    component: SamplePage
  },
  {
    path: "/embedded-success",
    name: "EmbeddedSuccessPage",
    component: EmbeddedSuccessPage
  },
  {
    path: "/welcome",
    name: "WelcomePage",
    component: WelcomePage
  },
  {
    path: "/teacher-welcome",
    name: "TeacherWelcomePage",
    component: TeacherWelcomePage
  },     
  {
    path: "/teacher-main",
    name: "TeacherMainPage",
    component: TeacherMainPage
  },
  {
    path: "/student-welcome",
    name: "StudentWelcomePage",
    component: StudentWelcomePage
  },     
  {
    path: "/student-main",
    name: "StudentMainPage",
    component: StudentMainPage
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: ErrorPage,
    props: true
  }             
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
