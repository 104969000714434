
// Env

export const SERVER_URL = process.env.VUE_APP_SERVER_URL;
export const CLEVER_AUTHORIZE_URL = process.env.VUE_APP_CLEVER_AUTHORIZE_URL;
export const EMBEDDED_ONBOARDING_OK = process.env.VUE_APP_EMBEDDED_ONBOARDING_OK;
export const EMBEDDED_ONBOARDING_ERROR = process.env.VUE_APP_EMBEDDED_ONBOARDING_ERROR;
export const LOCAL_SERVER_MODE = process.env.VUE_APP_LOCAL_SERVER_MODE === 'true';
export const CONSOLE_LOGGING = process.env.VUE_APP_CONSOLE_LOGGING === 'true';
export const DASHBOARD_URL = process.env.VUE_APP_DASHBOARD_URL;

// Strings

export const STRING_TRY_AGAIN = 'Please try again';
export const STRING_STUDENT_ONBOARDED = 'Ask your teacher to download the free Khan Kids mobile app!';
export const STRING_STUDENT_NOT_ONBOARDED = 'Ask your teacher to set up your class for the Khan Kids mobile app!';

// Errors

export const ERR_CANNOT_CREATE_CLASSES_TITLE = 'Cannot Create Classes';
export const ERR_CANNOT_CREATE_CLASSES = 'There was an error creating your classes. Please try again.';

export const ERR_NO_CLASSES_SELECTED_TITLE = 'No Classes Selected';
export const ERR_NO_CLASSES_SELECTED = 'There are no new classes selected. Please select one or more classes.';

export const ERR_SIGNING_IN_TITLE = 'Cannot Sign In';
export const ERR_SIGNING_IN = 'There was an error signing in. Please try again.';

export const ERR_MATCH_CLASS_NO_CLASS_SELECTED_TITLE = 'No Class Selected';
export const ERR_MATCH_CLASS_NO_CLASS_SELECTED = 'Please select a class to continue.';

export const ERR_MATCH_CLASS_NOT_ALL_MATCHED_TITLE = 'Unmatched Students';
export const ERR_MATCH_CLASS_NOT_ALL_MATCHED = "Not all students are matched. We'll add remaining Clever students to your account, and delete any Khan Kids students that don't have a Clever match. Do you wish to continue?";

export const ERR_NEED_CLASS_ACCOUNT_TITLE = 'Oops!';
export const ERR_NEED_CLASS_ACCOUNT = 'Please convert your Khan Kids account to a Class account first so you can use it with Clever. Go to the Parent Section and tap the menu at the top to convert.';
