
















import { mapGetters } from 'vuex'

import { Component, Prop, Vue } from "vue-property-decorator";

import * as consts from '@/consts';

@Component({
  computed: mapGetters([
  'embedded'
  ])  
})
export default class ErrorPage extends Vue {
  @Prop() message!: string;
  @Prop() title!: string;

  created(): void {
    this.$appMgr.log('created: ErrorPage');
  }

  mounted(): void {
    this.$appMgr.log('mounted: ErrorPage');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed: ErrorPage');
  }

  done(): void {
    window.location.href = consts.EMBEDDED_ONBOARDING_ERROR;
  }  
}
