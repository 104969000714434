




















import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class BaseDropdown extends Vue {
  @Prop() options!: string[];
  @Prop() default!: string;
  @Prop({ default: 0 }) tabIndex!: number;

  private open: boolean = false;
  private selected: string = '';

  created(): void {
    this.$appMgr.log('created');

    this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : ' ';

    // this.$appMgr.log(`Selected: *${this.selected}*`);
  }

  mounted(): void {
    this.$appMgr.log('mounted');
  }

  destroyed(): void {
    this.$appMgr.log('destroyed');
  }

  optionSelected(index: number): void {
    this.selected = this.options[index];
    this.open = false;
          
    this.$emit('optionSelected', index);    
  }
}

